<script>
import SubscriptionCancellationModal from "../../components/modals/modal-cancel-subscription"
import SettingFaq from './setting-faq.vue';
import { PLAN_DESCRIPTION, PLAN_FEATURES, PLAN_PUBLIC_NAMES, PLAN_SKU_TYPES, PREFLIGHT_PRICES } from '../../utils/constants';

const { FREE_PLAN, SUSPENDED_PLAN, TRIAL_PLAN, PREMIUM_PLAN } = PLAN_SKU_TYPES
const { FREE, STANDARD, PREMIUM, SUSPENDED } = PLAN_PUBLIC_NAMES
const { FREE_DESCRIPTION, STANDARD_DESCRIPTION, PREMIUM_DESCRIPTION } = PLAN_DESCRIPTION
const { FREE_FEATURES, STANDARD_FEATURES, PREMIUM_FEATURES } = PLAN_FEATURES
const FOREVER_FREE = "Forever Free"
const PREFLIGHT_MONTHLY_PRICE = 46;
const PREFLIGHT_ANNUAL_MONTHLY_PRICE = 33;

export default {
  name: 'pricePlan',
  components: {
    SubscriptionCancellationModal,
    SettingFaq
  },
  data() {
    return {
      selectedPlan: _.get(this.$A, 'Auth.account.subscriptionState.sku'),
      currentSubscription: this.emptySubscription(),
      totalUsers: 1,
      plans: [],
      annualPlans: {},
      preflightPlans: {},
      FOREVER_FREE,
      SUSPENDED
    }
  },
  async mounted() {
    await this.$A.GetCurAccountData();
    await this.getCurrentSub()
    const users = await this.$A.AccountService.All() || [];
    if (users) this.totalUsers = users.length || 1;
  },
  methods: {
    cancelCurrentSub: async function (data) {
      let newSub = await this.$A.BillingService.CancelSubscription();
      if (newSub.success !== undefined && !newSub.success) {
        return;
      }
      await this.getCurrentSub();
      await this.$A.GetCurAccountData();
      this.$root.$emit('nav::reload');

      let slackTextMessage = `Cancellation Reason(s): ${data.reason}.\nFeatures Requested: ${data.features}\nAdditional Feedback: ${data.feedback}`
      await this.$A.FeedbackService.CreateSlackMessage({
        slackChannel: "ns_purchase_events",
        subject: "Subscription Cancellation Notice",
        text: slackTextMessage
      })
    },
    updatePlanDetails: function (plan) {
      if (plan.publicName === FREE) {
        plan.publicName = FOREVER_FREE;
        plan.planDescription = FREE_DESCRIPTION;
        plan.features = FREE_FEATURES;
      } else if (plan.publicName === STANDARD) {
        plan.planDescription = STANDARD_DESCRIPTION;
        plan.features = STANDARD_FEATURES;
      } else if (plan.publicName === PREMIUM) {
        plan.planDescription = PREMIUM_DESCRIPTION;
        plan.features = PREMIUM_FEATURES;
      }
    },
    getCurrentSub: async function () {
      this.currentSubscription = await this.$A.BillingService.GetCurrentSubscription() || this.emptySubscription();
      const planKey = this.currentSubscription.plan.publicName === PREMIUM ? 'premium-1-api' : 'standard-1';
      if (this.currentSubscription.plan.cycleMonths === 12 && this.currentSubscription.planSku !== SUSPENDED_PLAN) {
        this.$set(this.annualPlans, planKey, true);
      }
      if (!_.isEmpty(this.currentSubscription.additionalSkus)) this.$set(this.preflightPlans, planKey, true)
      _.set(this, 'currentSubscription.plan.isUpgrade', false);
      _.set(this, 'currentSubscription.plan.isDownGrade', false);
      let plans = [];
      plans.push(this.currentSubscription.plan);

      this.currentSubscription.downGradeOptions.forEach(function (i) {
        i.isDownGrade = true;
        i.isUpgrade = false;
        plans.push(i)
      });
      this.currentSubscription.upgradeOptions.forEach(function (i) {
        i.isUpgrade = true;
        i.isDownGrade = false;
        plans.push(i)
      });

      plans.map((plan) => this.updatePlanDetails(plan))
      this.plans = plans.sort(function (a, b) {
        return a.unitPrice - b.unitPrice;
      });
      await this.$A.UpdateCurrentSubscriptionState(_.get(this.currentSubscription, 'subscriptionState'));
    },
    emptySubscription: function () {
      return {
        planSku: _.get(this, '$A.Auth.account.subscriptionState.sku', ''),
        plan: {
          publicCyclePrice: ""
        },
        subscriptionState: {
          remainingProofsOnPlan: 0,
        },
        downGradeOptions: [],
        upgradeOptions: []
      };
    },
    showCurrentUser(plan) {
      let isCurrentPlan = false;
      if (this.isFreeUser && plan.publicName === FOREVER_FREE) isCurrentPlan = true;
      else if (this.currentSubscription.planSku.includes(plan.publicName.toLowerCase())) isCurrentPlan = true;
      return isCurrentPlan;
    },
    preflightPrice(plan) {
      let preflightPrice = PREFLIGHT_MONTHLY_PRICE;
      if (this.annualPlans[plan.sku]) preflightPrice = PREFLIGHT_ANNUAL_MONTHLY_PRICE;
      return preflightPrice
    },
    togglePreflightAddOn(planSku) {
      this.$set(this.preflightPlans, planSku, !this.preflightPlans[planSku]);
    },
    toggleAnnualPayment(sku) {
      this.$set(this.annualPlans, sku, !this.annualPlans[sku]);
    },
    getPlanPrice(plan) {
      if (this.annualPlans[plan.sku]) {
        return plan.monthlyCostWhenPaidAnnually.split('/')[0];
      }
      return plan.publicCyclePrice.split('/')[0];
    },
    redirectToStripe(url) {
      const link = document.createElement('a')
      link.href = url
      link.target = '_blank'
      link.rel = 'noopener noreferrer'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    async handlePlanClick(plan) {
      if (plan.publicName === FOREVER_FREE || plan.publicName === SUSPENDED) return;
      const isAnnual = this.annualPlans[plan.sku] || false;
      const mainSku = isAnnual ? `${plan.sku}-annual` : plan.sku;
      const isPreflightSelected = this.preflightPlans[plan.sku] || false;
      let additionalSkus = {};

      if (isPreflightSelected) {
        additionalSkus[`preflights${isAnnual ? '-annual' : ''}`] = 1;
      }

      const checkoutSessionRequest = {
        stripeCheckoutSessionRequest: {
          "MainSku": mainSku,
          "AdditionalSkus": additionalSkus
        }
      }

      const result = await this.$A.BillingService.CreateCheckoutSession(checkoutSessionRequest);
      result?.url && this.redirectToStripe(result.url)
    },
    async redirectCustomerPortal() {
      const result = await this.$A.BillingService.CreateCustomerPortalSession()
      result?.url && this.redirectToStripe(result.url)
    },
    getButtonText(plan) {
      let buttonText = this.currentSubscription.planSku.includes(PREMIUM_PLAN) ? 'Downgrade' : 'Upgrade'
      if (this.isFreeUser && (plan.publicName === FOREVER_FREE || plan.publicName === SUSPENDED)) buttonText = 'Current Plan'
      else if (this.currentSubscription.planSku.includes(plan.publicName.toLowerCase())) buttonText = 'Update Plan'
      return buttonText
    },
    getButtonClasses(plan) {
      let buttonClasses = "btn btn-success w-100 font-weight-normal"
      if (plan.publicName === FOREVER_FREE || plan.publicName === SUSPENDED) buttonClasses = "btn btn-secondary no-click btn-hover w-100 font-weight-normal"
      else if (this.currentSubscription.planSku.includes(plan.publicName.toLowerCase())) buttonClasses = "btn btn-secondary w-100 font-weight-normal"
      return buttonClasses
    }
  },
  computed: {
    isFreeUser() {
      return this.currentSubscription.planSku === FREE_PLAN || this.currentSubscription.planSku === SUSPENDED_PLAN || this.currentSubscription.planSku === TRIAL_PLAN;
    },
    subscriptionDetails() {
      const plan = this.currentSubscription.plan;
      if (!plan || plan.publicName === FREE) return {};

      const { publicName, cycleMonths, publicCyclePrice, monthlyCostWhenPaidAnnually } = plan;
      const { additionalSkus } = this.currentSubscription;
      const users = publicName === PREMIUM && this.totalUsers < 5 ? 5 : this.totalUsers;
      const preflightAddon = Object.keys(PREFLIGHT_PRICES).find(sku => additionalSkus[sku]);
      const addons = preflightAddon ? PREFLIGHT_PRICES[preflightAddon] : 0;
      const removeDollarSign = value => parseFloat(value.replace('$', '').trim());

      const monthlyCostAnnually = (monthlyCostWhenPaidAnnually != null) ? removeDollarSign(monthlyCostWhenPaidAnnually.split('/')[0]) : "";
      const cyclePrice = removeDollarSign(publicCyclePrice.split('/')[0]);

      const isAnnual = cycleMonths === 12;

      const totalCost = isAnnual
        ? (((monthlyCostAnnually * users + addons) * 12).toFixed(2))
        : (cyclePrice * users + addons).toFixed(2);

      return {
        subscription: publicName,
        frequency: isAnnual ? 'Annual' : 'Monthly',
        costPerUser: isAnnual ? monthlyCostAnnually : cyclePrice,
        users,
        addons,
        totalCost
      };
    },
  },
}
</script>

<template>
  <div class="row card-container-wrapper custom-scrollbar">
    <div class="col-md-12 bg-white border-bottom border-gray-200 py-3 py-lg-4 header-section">
      <h4 class="heading-text">Plan & Billing</h4>
    </div>
    <div v-if="plans.length" class="col-md-12 p-0">
      <div class="d-xl-flex card-container">
        <div v-for="plan in plans" :key="plan.id" class="col-xl-4 p-0 pl-lg-4 mb-5" v-if="plan.cycleMonths === 1">
          <div class="bg-white h-100 rounded-3 border border-gray-200 p-4 d-flex flex-column">
            <div v-if="showCurrentUser(plan)" class="plan-label">Current Plan</div>
            <p class="text-gray-900 size-20 mb-3 fw-semibold">{{ plan.publicName }}</p>
            <p class="size-20 fw-semibold text-gray-900"
              :class="plan.sku === 'premium-1-api' ? 'mb-3' : 'amount-section'">
              <span class="price-text">{{ getPlanPrice(plan) }}</span>/month/user
            </p>
            <h6 v-if="plan.sku === 'premium-1-api'" class="text-gray-900 fs-6 fw-semibold mb-4">Starts at 5 Users</h6>
            <h6 class="text-gray-500 font-weight-normal mb-4">{{ plan.planDescription }}</h6>
            <ul class="list-unstyled mb-0 d-flex flex-column gap-3 mb-3">
              <li v-for="feature in plan.features" class="d-flex align-items-center gap-3">
                <div class="icon">
                  <img src="img/check.svg" alt="check-icon">
                </div>
                <h6 class="font-weight-normal m-0 text-gray-500">{{ feature }}</h6>
              </li>
            </ul>
            <div v-if="plan.publicName !== FOREVER_FREE && plan.publicName !== SUSPENDED">
              <div class="border-top border-gray-200 py-3">
                <label class="d-flex m-0 gap-2">
                  <div class="flex-shrink-0">
                    <input type="checkbox" class="hidden-checkbox" :checked="preflightPlans[plan.sku]"
                      @change="togglePreflightAddOn(plan.sku)">
                    <span class="styled-checkbox"></span>
                  </div>
                  <div class="flex-fill cursor-pointer">
                    <div class="d-flex align-items-center justify-content-between gap-2 mb-2">
                      <span class="fs-14 text-gray-700 font-weight-normal ml-1">Preflights Add-on</span>
                      <span class="text-gray-400 fs-14 font-weight-normal ms-auto">${{ preflightPrice(plan)
                        }}/month</span>
                    </div>
                    <div class="text-gray-500 ml-1 font-weight-normal fs-14">Turn customer artwork into print-ready PDFs
                      in seconds!</div>
                  </div>
                </label>
              </div>
              <div class="border-top border-gray-200 pt-3 mb-4">
                <div class="ashore-toggle">
                  <label class="switch m-0 d-flex align-items-center gap-2"><input type="checkbox" :checked="annualPlans[plan.sku]"
                      @change="toggleAnnualPayment(plan.sku)">
                    <span class="slider round"></span>
                    <span class="fs-14 text-gray-900 pt-1 cursor-pointer font-weight-normal ml-5 text-nowrap">Pay Annually (Save 20%)</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column gap-12 mt-auto" :class="{ 'pt-4': !isFreeUser }">
              <button :class="getButtonClasses(plan)" @click="handlePlanClick(plan)">{{ getButtonText(plan) }}</button>
            </div>
          </div>
        </div>
        <div v-if="!isFreeUser" class="col-xl-4 p-0 pl-lg-4 mb-5">
          <div class="bg-white h-100 rounded-3 border border-gray-200  p-4 d-flex flex-column">
            <h4 class="text-gray-900 fs-5 mb-3 fw-bold pb-4 subscription-title">Your Subscription</h4>

            <ul class="mb-4 list-unstyled d-flex flex-column gap-4">
              <li class="d-flex flex-wrap gap-2 justify-content-between">
                <span class="text-gray-900 subscription-detail">Subscription</span>
                <span class="subscription-value">{{ subscriptionDetails.subscription }}</span>
              </li>

              <li class="d-flex flex-wrap gap-2 justify-content-between">
                <span class="text-gray-900 subscription-detail">Payment Frequency</span>
                <span class="subscription-value">{{ subscriptionDetails.frequency }}</span>
              </li>

              <li class="d-flex flex-wrap gap-2 justify-content-between">
                <h4 class="text-gray-900 fw-semibold mb-4">$ {{ subscriptionDetails.costPerUser }} Per Month Per User
                </h4>
              </li>
              <li>
                <hr class="my-0">
              </li>
            </ul>

            <ul class="mb-4 list-unstyled d-flex flex-column gap-4">
              <li class="d-flex flex-wrap gap-2 justify-content-between">
                <span class="text-gray-900 subscription-detail">Total Users</span>
                <span class="text-gray-900 subscription-detail">{{ subscriptionDetails.users }}</span>
              </li>

              <li v-if="subscriptionDetails.addons" class="d-flex flex-wrap gap-2 justify-content-between">
                <span class="text-gray-900 subscription-detail">Preflights Add-on</span>
                <span class="text-gray-900 subscription-detail">${{ subscriptionDetails.addons }}/m</span>
              </li>
              <li>
                <hr class="my-0">
              </li>
            </ul>

            <ul class="mb-4 list-unstyled d-flex flex-column gap-4">

              <li class="d-flex flex-wrap gap-2 justify-content-between">
                <h4 class="text-gray-900 fw-semibold">{{ subscriptionDetails.frequency }} Cost</h4>
                <h4 class="text-gray-900 fw-semibold">${{ subscriptionDetails.totalCost }}</h4>
              </li>

            </ul>

            <div class="d-flex flex-column gap-2 mt-auto">
              <button class="subscription-btn" @click="redirectCustomerPortal">Manage Account</button>
              <button class="subscription-btn" @click="$refs.cancelCurrentSubscriptionModal.open()">Cancel Plan</button>
            </div>
          </div>
        </div>
      </div>
      <SettingFaq v-if="isFreeUser" />
    </div>
    <SubscriptionCancellationModal ref="cancelCurrentSubscriptionModal" v-on:confirmed="cancelCurrentSub"
      :question="'Are you sure you want to cancel your ' + currentSubscription.plan.publicName + ' subscription?'" />
  </div>
</template>

<style scoped>
.card-container-wrapper {
  max-height: calc(100vh - 65px);
  overflow-y: auto;
  margin-top: 65px;
}

.header-section {
  margin-bottom: 56px;
}

.heading-text {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: #1B1B1C;
  margin: 0 0 0 105px;
}

.card-container {
  padding: 0px 94px
}

.amount-section {
  margin-bottom: 56px;
}

.price-text {
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
  margin-right: 4px;
}

.size-20 {
  font-size: 20px;
}

.cursor-pointer {
  cursor: pointer;
}

.hidden-checkbox {
  display: none;
}

.styled-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 1px solid #B5B5B8;
  background-color: #F9F9F9;
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin-top: 3px;
}

.hidden-checkbox:checked+.styled-checkbox {
  background-color: #28a745;
  border-color: #28a745;
}

.hidden-checkbox:checked+.styled-checkbox::after {
  content: "✔";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  color: white;
  font-weight: bold;
}

.btn-secondary {
  background-color: #EEF2F5;
  color: #444444;
  height: 38px;
  font-weight: 400 !important;
}

.no-click:active {
  background-color: #EEF2F5 !important;
  color: #444444 !important;
}

.btn-hover {
  cursor: default !important;
  border: none !important;
}

.ashore-toggle .slider {
  background-color: #D5D5D8;
}

.subscription-title {
  font-size: 20px;
}

.subscription-detail {
  font-size: 16px;
  margin-bottom: 24px;
}

.subscription-value {
  font-size: 16px;
  color: #6B7280;
}

.subscription-btn {
  padding: 9px 17px 9px 17px;
  border-radius: 6px;
  border: 1px solid #B5B5B8;
  background-color: #F9F9F9;
  box-shadow: 0px 1px 2px 0px #080B0D0D;
  color: #3E3E40;
}

.subscription-btn:hover {
  background-color: #D1D1D1;
}

.plan-label {
  width: 125px;
  height: 28px;
  padding: 6px 16px 6px 16px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 600;
  color: #FFFFFF;
  text-transform: uppercase;
  background-color: #019256;
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #ffff;
}

.custom-scrollbar::-webkit-scrollbar {
  margin-top: 100px;
}

@media (max-width: 768px) {
  .card-container {
    padding: 12px 24px
  }

  .header-section {
    margin-bottom: 30px;
  }

  .heading-text {
    margin-left: 15px;
  }
}

@media (max-width: 992px) {
  .card-container-wrapper {
    margin-top: 0px;
  }
}

@media (max-width: 500px) {
  .card-container {
    padding: 2px 16px
  }

  .heading-text {
    margin-left: 5px;
  }
}
</style>
